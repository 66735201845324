import {useSelector as rawUseSelector, TypedUseSelectorHook, useDispatch} from "react-redux";
import {configureStore, Middleware} from "@reduxjs/toolkit";
import axios from "axios";
import {
    persistStore,
} from 'redux-persist';
import rootReducer, {RootState} from './rootReducer'

const customMiddleware: Middleware<{}, RootState> = store => next => action => {
    // @ts-ignore
    axios.defaults["headers"]['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.put['Content-Type'] = 'application/json';
    axios.defaults.headers.delete['Content-Type'] = 'application/json';
    axios.defaults.headers.get['Content-Type'] = 'application/json';
    next(action);
};

// @ts-ignore
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat([customMiddleware]),
    devTools: process.env.NODE_ENV !== 'production'
});


export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;
export const persistor=persistStore(store);
export default store
