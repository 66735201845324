import * as React from 'react';
import "./style.css"
import {useTranslation} from "react-i18next";
import {namespaces} from "../../i18n/i18n.constants";

interface AnnouncementProps {
    isVisible?: boolean;
}

export const Announcement=({isVisible}: AnnouncementProps)=> {

    const { t } = useTranslation();
    return (
        <>
            {
                isVisible &&  <div className="contInfoBooking">
                    <div className="icon">i</div>
                    <span>{t('paragraph'??'',{ ns: namespaces.announcement})}</span>
                </div>
            }


        </>
    );
}

